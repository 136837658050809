import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ApiService } from 'app/shared/api/services/api-call.service';
import { NetworkState } from 'app/shared/state/network/network.state';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  private version = environment.version;
  constructor(private store: Store, private api: ApiService, private toastController: ToastController) {}
  public async checkVersion(): Promise<boolean> {
    const isBuilding = await this.checkBuildStatus();
    const isConnected = this.store.selectSnapshot(NetworkState.isConnected);
    if (!isConnected || isBuilding) {
      return false;
    }

    const { version } = await this.api._get<{ version: string }>('version/ionic');
    if (version !== this.version) {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map(name => caches.delete(name)));

      if (navigator.serviceWorker) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const registration of registrations) {
          await registration.unregister();
        }
      }
      await this.presentToast('top', 'App is updating, please wait...');
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      await this.presentToast('top', 'App is up to date');
      return false;
    }
  }

  public async presentToast(position: 'top' | 'middle' | 'bottom', message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 3000,
      position,
    });

    await toast.present();
  }
  private async checkBuildStatus(): Promise<boolean> {
    const data = await this.api._get<any>('build/ionic');
    if (data.app?.in_progress_deployment?.phase) {
      await this.presentToast('top', 'App is currently building, please wait...');
      return true;
    } else {
      return false;
    }
  }
}
