import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { ApiService } from 'app/shared/api/services/api-call.service';
import { Files, ImagePosition, RecursiveUpload, UploadImagesList, UploadSize } from 'app/types';
import { DeepCopyService } from '../deep-copy/deep-copy.service';
import { Filesystem } from '@capacitor/filesystem';
import { Buffer } from 'buffer';
import { Document } from 'app/shared/pages/document/types';
import { Document as DocumentAction } from 'app/shared/pages/document/state/document.actions';
import { PhotoUploadActions } from './state/photo-upload.actions';
import { AuthState } from 'app/shared/auth/state/auth.state';
import * as Sentry from '@sentry/angular';
@Injectable({
  providedIn: 'root',
})
export class PhotoUploadService {
  public isUploading: boolean = false;
  private _platform: Platform;
  private id: string;
  private uploadSize: UploadSize = {
    current: 0,
    size: 0,
    set _size(value: number) {
      this.current += value;
      this.size += value;
    },
  };
  private imagesPosition: Array<ImagePosition> = [];
  private uploadImagesList: UploadImagesList = {
    images: [],
  };
  constructor(public platform: Platform, private api: ApiService, private store: Store, private deep: DeepCopyService) {
    this._platform = platform;
  }

  async upload(document: Document, id: string): Promise<void> {
    this.isUploading = true;
    this.store.dispatch(new PhotoUploadActions.SetUploading(true, id));
    this.id = id;
    for (let index = 0; index < document.data.areas.length; index++) {
      const area = document.data.areas[index];
      await this.recursiveUpload({ area, index, property_id: document.property_id });
    }
    await this.chunkUpload(true);
    this.store.dispatch(new PhotoUploadActions.SetUploading(false, id));
    this.isUploading = false;
  }
  private async chunkUpload(forceUpload?: boolean): Promise<void> {
    if (forceUpload || this.uploadSize.current >= 5000000) {
      const uploadSize: number = this.uploadImagesList.images.length;
      const form = new FormData();
      form.append('uuid', this.id.toString());
      form.append('positions', JSON.stringify(this.imagesPosition));
      for (let i = 0; i < this.uploadImagesList.images.length; i++) {
        form.append(`image-${i}`, this.uploadImagesList.images[i].image, this.uploadImagesList.images[i].name);
        form.append(`path-${i}`, this.uploadImagesList.images[i].path);
      }
      await this.api.__post('upload/images', form);
      //tady bylo if (this.uploadImagesList.images.length > 0) ale nemenilo to status kdyz neni zadny obrazek
      this.uploadImagesList.images = [];
      this.imagesPosition = [];
      this.uploadSize.current = 0;
      this.store.dispatch(new DocumentAction.DocumentImageUpdate.UpdateUploadSize(uploadSize));
    }
  }
  private async recursiveUpload(_: RecursiveUpload): Promise<void> {
    for (const item of _.area.items) {
      for (let i = item.files.length - 1; i >= 0; i--) {
        if (item.files[i].isUploaded === false) {
          const _path = `/${_.property_id}/${this.id}/${this.clearChar(_.area.areaName)}/${this.clearChar(item.itemName)}`;
          if (item.files[i].group && item.files[i].group.length > 0) {
            for (let j = item.files[i].group.length - 1; j >= 0; j--) {
              if (item.files[i].group[j].isUploaded === false) {
                const _photoUploadPath = `/${_.property_id}/${this.id}/${this.clearChar(_.area.areaName)}/${this.clearChar(
                  item.itemName
                )}/group-${j}`;

                const compressedFile = await this.compressFile(item.files[i].group[j]);
                if (compressedFile === null) {
                  continue;
                }
                this.uploadImagesList.images.push({
                  name: item.files[i].group[j].fileName,
                  image: compressedFile as Blob,
                  path: _photoUploadPath,
                });
                const file = this.deep.copy<Files>(item.files[i].group[j]);
                file.fileSrc = null;
                this.imagesPosition.push({
                  area: _.index,
                  item: _.area.items.indexOf(item),
                  file: i,
                  groupIndex: j,
                  data: file,
                  isGroup: true,
                });
                this.updateImageTime(item.files[i].group[j]);
              }
            }
          } else {
            const compressedFile = await this.compressFile(item.files[i]);
            if (compressedFile === null) {
              continue;
            }
            this.uploadImagesList.images.push({
              name: item.files[i].fileName,
              image: compressedFile as Blob,
              path: _path,
            });
            const file = this.deep.copy<Files>(item.files[i]);
            file.fileSrc = null;
            this.imagesPosition.push({
              area: _.index,
              item: _.area.items.indexOf(item),
              file: i,
              data: file,
              isGroup: false,
            });
            this.updateImageTime(item.files[i]);
          }
        }
        await this.chunkUpload();
      }
    }
  }

  private clearChar(str: string): string {
    return str.replace(/[^a-zA-Z0-9]/g, '_');
  }

  private updateImageTime(file: Files): void {
    const date = Date.now();
    const fileName = `${file.fileName?.split('_')[0]}_${file.fileName?.split('_')[1]}_${date}.jpg`;
    file.fileName = fileName;
    file.fileUploadEdit = {
      user: this.store.selectSnapshot(AuthState.user),
      time: Date.now(),
    };
  }

  private async compressFile(file: Files): Promise<Blob | string> | null {
    if (this._platform.is('hybrid')) {
      const _file = await Filesystem.readFile({
        path: file.path,
      });

      if (typeof _file.data === 'string') {
        const byteString = Buffer.from(_file.data, 'base64');
        const blob = new Blob([byteString], { type: 'image/jpeg' });
        this.uploadSize._size = blob.size;
        return blob;
      } else {
        Sentry.captureException('File data is not a string');
      }
    }
    if (file.blob instanceof Blob) {
      this.uploadSize._size = file.blob.size;
      return file.blob;
    }
    if (Array.isArray(file.blob)) {
      try {
        const blob = await fetch(file.fileSrc).then(response => response.blob());
        this.uploadSize._size = blob.size;
        return blob;
      } catch (error) {
        console.error('Can not download blob', error);
        return null;
      }
    }
    return null;
    // return await this.compress.compressFile(file.fileSrc, -1, 100, 50);
  }
}
