import { Property, PropertyList, Archive as _Archive } from '../types';

export namespace PropertyListStateActions {
  export class Select {
    static readonly type = '[Property List] select ';
    constructor(public id: number) {}
  }
  export class Add {
    static readonly type = '[Property List ] add ';
    constructor(public property: Property) {}
  }
  export class Update {
    static readonly type = '[Property List] update ';
    constructor(public property: Property, public id: number) {}
  }
  export class Archive {
    static readonly type = '[Property List] archive ';
    constructor(public property: PropertyList) {}
  }
  export class Load {
    static readonly type = '[Property List] load ';
    constructor(public archive: _Archive) {}
  }
  export class LoadPage {
    static readonly type = '[Property List] Load Page ';
    constructor(public payload: { page: number; limit: number; archived: _Archive; search: string }) {}
  }
  export class Delete {
    static readonly type = '[Property List] delete ';
    constructor(public property: PropertyList) {}
  }
}
