import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ApiService } from 'app/shared/api/services/api-call.service';
import { CompanySettings, GlobalSettings, PaginationOptions } from 'app/types';
import { _defaultAreas } from 'app/shared/variables/areas-default';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DeepCopyService } from 'app/shared/services/deep-copy/deep-copy.service';
import {
  Document,
  DocumentInfo,
  DocumentList,
  DocumentTemplate,
  DocumentTemplateLink,
  DocumentType,
  GetDocumentListResponse,
  ShareDocument,
  UploadImageStatusEnum,
} from '../types';
import { PropertyListState } from 'app/admin-layout/pages/properties/state/property-list.state';
import { PhotoUploadService } from 'app/shared/services/upload/photo-upload.service';
import { Document as DocumentAction } from '../state/document.actions';
import { DocumentState } from '../state/document.state';
import { OfflineStorageState } from 'app/shared/offline/state/offline-storage.state';
import { OfflineStorage } from 'app/shared/offline/state/offline-storage.actions';
import { NetworkState } from 'app/shared/state/network/network.state';
import { v4 as _uuid } from 'uuid';
import { FilterBy } from '../../filter/types';
import { ApiResponse } from 'app/shared/api/types';
import { ModalController } from '@ionic/angular';
import { ModalCreateDocumentComponent } from '../pages/create-document.component';
import { property } from 'cypress/types/lodash';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  @Select(DocumentState.documentList) documentList$: Observable<Array<DocumentList>>;
  public isLoading: boolean = false;
  public selectedDocument: DocumentType = 'work-order';
  public defaultFilterBy: FilterBy = {
    dates: [],
    labels: [],
    properties: [],
    name: '',
    property_id: null,
    assessment_type: [],
  };
  public filterBy: BehaviorSubject<FilterBy> = new BehaviorSubject(this.defaultFilterBy);
  public paginationOptions: PaginationOptions = {
    page: 1,
    limit: 10,
    isActiveFilter: false,
  };
  constructor(
    private api: ApiService,
    private store: Store,
    private uploadImagesService: PhotoUploadService,
    private router: Router,
    private deep: DeepCopyService,
    private modalCtrl: ModalController
  ) {}
  public async createCopyDocument(info: DocumentInfo): Promise<ShareDocument> {
    const document = structuredClone(this.store.selectSnapshot(DocumentState.document));
    // document.data.areas = this.filterDocumentAreas(document.data.areas);
    const { id } = await this.api._post<{ id: string }>(`account/tenant`, {
      data: {
        name: info.sharedInfo.fullName,
        email: info.sharedInfo.email,
        property_id: this.store.selectSnapshot(PropertyListState.id),
      },
    });
    const data = {
      document_uuid: document.uuid,
      tenant_uuid: id,
      property_id: this.store.selectSnapshot(PropertyListState.id),
      data: document.data,
      info,
      token: true,
      type: document.type,
    };
    return await this.api._post<ShareDocument>(`document`, { data });
  }
  public async update(info: DocumentInfo, uuid: string): Promise<boolean> {
    const data = {
      info,
      uuid,
    };
    return await this.api._patch<boolean>(`document/${uuid}`, { data });
  }
  public async getCompanySettings(): Promise<CompanySettings> {
    const { companySettings } = await this.api._get<GlobalSettings>('settings');
    return companySettings;
  }
  public async createDocument(info: DocumentInfo, type: DocumentType): Promise<Document> {
    if (this.store.selectSnapshot(NetworkState.isConnected)) {
      return await this.api._post<Document>(`document`, { data: await this.defaultTemplate(info, type) });
    } else {
      return this.defaultTemplate(info, type);
    }
  }
  public async convertToWorkOrder(info: DocumentInfo, propertyId: number): Promise<Document> {
    const data = {
      uuid: null,
      property_id: propertyId,
      info,
      data: {
        areas: _defaultAreas,
      },
      type: 'work-order',
    };
    return await this.api._post<Document>(`document`, { data });
  }
  async openCreateDocumentModal(propertyId: number) {
    const isConnected = this.store.selectSnapshot(NetworkState.isConnected);
    const templates = isConnected ? await this.getTemplates() : this.store.selectSnapshot(OfflineStorageState.templateList);
    const settings = await this.api._get<GlobalSettings>('settings');
    const { assessmentTypes } = settings;
    const modal = await this.modalCtrl.create({
      component: ModalCreateDocumentComponent,
      componentProps: {
        templates,
        assessmentTypes,
        type: this.selectedDocument,
        propertyId,
      },
    });
    await modal.present();
  }
  public async getTemplates(): Promise<Array<DocumentTemplateLink>> {
    try {
      const { data } = await this.api._get<ApiResponse<Array<DocumentTemplateLink>>>('templates');
      const result = data.map(template => ({
        uuid: template.uuid,
        name: template.name,
        info: template.info,
      }));
      result.unshift({ uuid: 'null', name: 'Default', info: null });
      this.store.dispatch(new OfflineStorage.TemplateList.Add(result));
      return result;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  public viewReferenceReport(document: Document) {
    const url = this.router.createUrlTree(['/app/report/work-order'], {
      queryParams: { uuid: document.info.reference.report.uuid, size: 'fit' },
    });
    window.open(this.router.serializeUrl(url).toString(), '_blank');
  }
  public async editDocument(document: Document): Promise<boolean | void> {
    const online = this.store.selectSnapshot(NetworkState.isConnected);
    if (online && document.info.isOffline) {
      const offlineUuid = document.uuid;
      delete document.info.isOffline;
      const { uuid } = await this.api._post<Document>('document', { data: document });
      document.uuid = uuid;
      await this.uploadImages(document, offlineUuid);
    } else if (online) {
      document.info.token = false;
      document.info.notification = false;
      await this.api._patch(`document/${document.uuid}`, { data: document });
      await this.uploadImages(document);
    } else if (
      (online && document.info.uploadImageStatus.status === UploadImageStatusEnum.NOT_UPLOADED) ||
      document.info.uploadImageStatus.status === UploadImageStatusEnum.OFFLINE
    ) {
      document.info.token = false;
      document.info.notification = false;
      await this.api._patch<boolean>(`document/${document.uuid}`, { data: document });
      await this.uploadImages(document);
    }
  }

  public async getDocuments(propertyId: number | string, type: DocumentType): Promise<Array<DocumentList>> {
    try {
      const online = this.store.selectSnapshot(NetworkState.isConnected);
      if (!online) return [];
      this.isLoading = true;
      this.resetPagination();
      const response = await this.api._post<GetDocumentListResponse>(
        `filter?page=${this.paginationOptions.page}&limit=${this.paginationOptions.limit}`,
        {
          filters: {
            ...this.filterBy.getValue(),
            property_id: propertyId,
            type,
          },
          type: 'document',
        }
      );
      this.isLoading = false;
      this.paginationOptions.type = type;
      this.paginationOptions.lastPage = response.lastPage;

      return response.data;
    } catch (err) {
      this.isLoading = false;
      return [];
    }
  }

  public async getDocument(id: string): Promise<Document> {
    const online = this.store.selectSnapshot(NetworkState.isConnected);
    const offlineDocument = this.store.selectSnapshot(OfflineStorageState.documentList).find(doc => doc.uuid === id);
    if (online && !offlineDocument) {
      return await this.api._get<Document>(`document/${id}`);
    } else {
      return this.store.selectSnapshot(OfflineStorageState.documentList).find(doc => doc.uuid === id);
    }
  }

  public async deleteDocument(id: string): Promise<boolean> {
    const offlineDocument = this.store.selectSnapshot(OfflineStorageState.documentList).find(doc => doc.uuid === id);
    if (offlineDocument) this.store.dispatch(new OfflineStorage.Remove(offlineDocument.uuid));
    else return await this.api._delete<boolean>(`document/${id}`);
  }

  public async loadMore(): Promise<void> {
    const online = this.store.selectSnapshot(NetworkState.isConnected);
    if (!online) return;
    const propertyId = this.store.selectSnapshot(PropertyListState.property)?.property_id;
    if (this.paginationOptions.type !== this.selectedDocument) this.resetPagination();
    if (this.paginationOptions.page >= this.paginationOptions.lastPage) return;
    this.paginationOptions.isLoading = true;
    this.paginationOptions.page++;
    const response = await this.api._post<GetDocumentListResponse>(
      `filter/?page=${this.paginationOptions.page}&limit=${this.paginationOptions.limit}`,
      {
        filters: {
          ...this.defaultFilterBy,
          property_id: propertyId,
          type: this.selectedDocument,
        },
        type: 'document',
      }
    );
    this.paginationOptions.type = this.selectedDocument;
    this.store.dispatch(new DocumentAction.DocumentList.LoadMore(response.data));
    this.paginationOptions.isLoading = false;
  }
  private resetPagination() {
    this.paginationOptions.page = 1;
    this.paginationOptions.isLoading = false;
  }
  private async defaultTemplate(documentInfo: DocumentInfo, type: DocumentType): Promise<Document> {
    let areas = _defaultAreas;
    if (documentInfo?.template_id !== 'null') {
      const { data, info } = await this.api._get<DocumentTemplate>(`template/${documentInfo.template_id}`);
      areas = data.areas;
      const config = info.config ?? { description: false, imageTags: [], categoryTags: [], statusList: [] };
      documentInfo.templateConfig = config;
    }
    return {
      uuid: this.store.selectSnapshot(NetworkState.isConnected) ? null : _uuid(), //for offline storage it will have a temp id
      document_uuid: null,
      token: null,
      property_id: documentInfo.property_id ? documentInfo.property_id : this.store.selectSnapshot(PropertyListState.id),
      info: {
        ...documentInfo,
        isOffline: this.store.selectSnapshot(NetworkState.isConnected) ? false : true,
      },
      data: {
        areas,
      },
      type,
      assigned_users: [],
    };
  }
  private async uploadImages(document: Document, offline?: string) {
    if (offline) {
      try {
        await this.uploadImagesService.upload(document, document.uuid);
        this.store.dispatch(new OfflineStorage.Remove(offline));
        this.store.dispatch(new DocumentAction.DocumentList.Update(document, offline));
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        await this.uploadImagesService.upload(document, document.uuid);
      } catch (err) {
        console.error(err);
      }
    }
  }
}
