import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import * as Sentry from '@sentry/angular';
if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: 'https://1d401a3b927cc0fe490a2e9a61ed40ed@o4505876124336128.ingest.sentry.io/4505876129251328',
    // To set your release and dist versions
    release: 'birdrockres@' + environment.version,
    dist: '1',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
    tracePropagationTargets: ['localhost', environment.api],
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
