export const environment = {
  production: true,
  api: 'https://api.birdrockres.com/', //'https://api.birdrockres.com/',
  url: 'https://app.birdrockres.com/', //'https://api.birdrockres.com/',
  version: require('../../package.json').version,
  app: 'app.birdrockres.com',
  storage: 'https://birdrockres-storage.sfo3.cdn.digitaloceanspaces.com/',
  lowImage: 'https://api.birdrockres.com/generate-image',
  imageKit: 'https://ik.imagekit.io/birdrockres/',
};
