import { LoadingController, ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loading: HTMLIonLoadingElement;
  private isShowing = false;
  private isLoading = false;

  constructor(private loadingController: LoadingController, private toastController: ToastController) {}
  async presentLoader(message: string) {
    this.isLoading = true;
    const loading = await this.loadingController.create({
      message,
      backdropDismiss: true,
    });
    await loading.present();
    if (!this.isLoading) {
      await loading.dismiss();
    }
  }
  async updateLoader(message: string) {
    this.isLoading = true;
    try {
      const loading = await this.loadingController.getTop();
      if (loading) loading.message = message;
    } catch (err) {
      console.log(err);
    }
  }

  async dismissLoader() {
    this.isLoading = false;
    try {
      await this.loadingController.getTop().then(async loading => {
        if (loading) {
          await loading.dismiss();
        }
      });
    } catch (err) {
      console.log(err);
    }
  }
  async presentToast(position: 'top' | 'middle' | 'bottom', message: string, icon?: string) {
    const toast = await this.toastController.create({
      message,
      duration: 3000,
      position,
      icon,
      color: 'success',
    });

    await toast.present();
  }
  async presentToastError(message: string) {
    const toast = await this.toastController.create({
      message,
      position: 'top',
      color: 'danger',
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }
}
