import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, of } from 'rxjs';
import { ApiService } from 'app/shared/api/services/api-call.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from 'app/shared/pages/permission-wrapper/types';
import { environment } from 'environments/environment';
import { LoaderService } from 'app/shared/services/loading/loader.service';
import { Authorization } from '../types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private api: ApiService, private http: HttpClient, private loader: LoaderService) {}
  logout(): Observable<any> {
    return new Observable<any>(observer => {
      observer.next(true);
    });
  }
  async login(form): Promise<Authorization> {
    const credentials = { email: form.login, password: form.password };
    try {
      const response = await lastValueFrom(this.http.post<Authorization>(environment.api + 'login', credentials));
      return response;
    } catch (error) {
      const { code, message } = error.error;
      await this.loader.presentToastError(`${code}:${message}`);
      return error;
    }
  }
  async getUser(id: number): Promise<User> {
    return await this.api._get<User>(`account/${id}`);
  }

  // startAutoLogout(){
  //   this.idleService.startWatching();
  //   this.idleService.onTimerStart().subscribe(count => console.log('should reset timer if mousemove event happen here'));
  //   this.idleService.onTimeout().subscribe(() => {
  //     this.store.dispatch(new Auth.Logout())
  //     console.log('Do some actions here such as clear session and logout')
  //   });
  //   this.idleService.getPing().subscribe(() => console.log('ping alive'));
  // }
  // endAutoLogout(){
  //   this.idleService.stopWatching();
  //   this.idleService.stopTimer()
  // }
}
